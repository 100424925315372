import * as React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"

// The bridge for Gatsby Background Image in V3
import { BgImage } from 'gbimage-bridge'

// All the gatsby-plugin-image goodness
import { StaticImage, getImage } from "gatsby-plugin-image"

import { Container, Carousel } from "react-bootstrap"

const SecondPage = ({data}) => (
  <Layout>
    <BgImage 
      image={getImage(data.indexImage)} 
      className="masthead"
    >
       <div className="black-overlay">
            <div className="content-box">
              <h1>The Wick 2.0</h1>
            </div>
          </div>
    </BgImage>

    <Container>
      <p className="summary">
      Sitting adjacent to the beautiful Mary Ross Waterfront Park and situated on the East River The Wick 2.0 offers spectacular views from many suites. With its classic tabby exterior and spacious offices this recently renovated property provides a quiet work environment in a tranquil setting right in the middle of Historic Downtown Brunswick, Georgia. Its amenities include a central meeting space, a small kitchenette, and mail center. These spaces are perfect for those who are seeking networking opportunities and the ability to eat lunch by the marina daily! The Wick 2.0 offers nearly 30 private offices ranging from 100 - 250 square feet.
      </p>
    </Container>

    <Container className="singleCol">
      <Carousel autoPlay interval={80000} touch={true}>
        <Carousel.Item>
        <StaticImage
          src="../images/wick-2/wick-2-01.jpg"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="The Wick"
          className="d-block img-fluid"
        />
        </Carousel.Item>
        <Carousel.Item>
          <StaticImage
            src="../images/wick-2/wick-2-02.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="The Wick"
            className="d-block img-fluid"
          />
        </Carousel.Item>
        <Carousel.Item>
          <StaticImage
            src="../images/wick-2/wick-2-03.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="The Wick"
            className="d-block img-fluid"
          />
        </Carousel.Item>
        <Carousel.Item>
          <StaticImage
            src="../images/wick-2/wick-2-04.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="The Wick"
            className="d-block img-fluid"
          />
        </Carousel.Item>
      </Carousel>
    </Container>

    <div className="subhead">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3414.5439487016874!2d-81.49991864882654!3d31.150177672352864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e4d816e1abc4b7%3A0xdf3be784287a1fc9!2s127%20F%20St%2C%20Brunswick%2C%20GA%2031520!5e0!3m2!1sen!2sus!4v1575306257337!5m2!1sen!2sus" width="100%" height="100%" title="helo" frameborder="0"></iframe>
    
    </div>

  </Layout>
)

export const Head = () => <Seo title="The Wick 2.0" />

export default SecondPage

export const query = graphql`
  query {
    indexImage: file(relativePath: {eq: "wick-2-header-img.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2000
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`